
export default [
    // {
    //     type: 'selection',
    //     width: 38,
    //     align: 'center'
    // },

    {
        title: 'First Name',
        key: 'firstname',
        minWidth: 40,
    },
    {
        title: 'Last Name',
        key: 'lastname',
        minWidth: 40,
    },
    {
        title: 'Industry',
        key: 'Industry',
        minWidth: 80,
    },
    {
        title: 'Services',
        key: 'services',
        minWidth: 80,
    },
    {
        title: 'Mobile',
        key: 'mobile',
        minWidth: 40,
    },
    {
        title: 'Email',
        key: 'email',
        minWidth: 60,
    },
]
