<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      :title="title"
      slot="title"
      link="/helpContent/vtiger"
    />
    <ValidationObserver ref="validator">
      <FormRow >
        <div class="col-sm-3" >
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="Vtiger_User_Id"
            v-model="filtering.staffId"
            rules="required"
            v-if="uservTigerReport"
          />
        </div>
        <div class="col-sm-2" >
          <FormButton
            style="margin-top: 5px"
            type="primary"
            @click="search"
            :rounded="true"
            v-if="uservTigerReport"
          >
            <Icon type="ios-search" v-if="uservTigerReport" />
          </FormButton>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-2">
          <p>Total Records : {{totalRecords }}</p>
        </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="reportData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
      v-if="!uservTigerReport"
    >
      <!-- :pagination="pagination" -->
    </DataTable>
    <DataTable
      :actions="true"
      :data="reportData"
      :columns="tableConfigUser"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
      v-else-if="uservTigerReport"
    >
      <!-- :pagination="pagination" -->
    </DataTable>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import { vTigerReport, getvTigerUsers } from "../api";
import tableConfig from "./table";
import tableConfigUser from "./userVtigerData";
import { ValidationObserver } from "vee-validate";
// import validateSelectedRows from "Mixins/validateSelectedRows";
export default {
  name: "List",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
        message: "",
      },
      filtering: {
        staffId:null,
      },
      sorting: {
        key: "NAME",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      totalRecords:0,
      tableConfig,
      tableConfigUser,
      reportData: [],
      staffList: [],
      uservTigerReport: false,
      title:'Vtiger Industry Stats',
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    "$route.name": function(val) {
      if (val === "vTigerReport") {
        this.title='Vtiger Industry Stats';
        this.uservTigerReport = false;
        this.filtering.staffId=null;
        this.totalRecords=0;
        this.getDataByFilter();
      } else if (val === "userVtigerData") {
        this.title='User vTiger Stats';
        this.uservTigerReport = true;
        this.totalRecords=0;
        this.getvTigerUsers();
      }
    },
  },
  created() {
    if (this.$route.name === "vTigerReport") {
      this.title='Vtiger Industry Stats';
      this.uservTigerReport = false;
      this.filtering.staffId=null;
      this.totalRecords=0;
      this.getDataByFilter();
    } else if (this.$route.name === "userVtigerData") {
      this.title='User vTiger Stats';
      this.totalRecords=0;
      this.uservTigerReport = true;
      this.getvTigerUsers();
    }
  },
  methods: {
    search() {
        this.$refs.validator.validate().then((result) => {
        if (result) {
      this.pagination.page = 1;
      this.getDataByFilter();
            }
    })
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    async getvTigerUsers() {
      this.uservTigerReport = true;
      await getvTigerUsers().then((res) => {
        this.staffList = res.data;
      });

      this.reportData = [];
    },
    getDataByFilter() {
      setTimeout(() => {
     
        // this.showLoader();
        const { key, order } = this.sorting;
        const { perPage, page } = this.pagination;
        const filters = {};
        filters.staffId = this.filtering.staffId;

        const data = {
          filterjson: {
            filter: [filters],
            sort: [
              {
                key,
                order,
              },
            ],
            paging: [
              {
                startIndex: page,
                pageSize: perPage,
              },
            ],
          },
        };
        vTigerReport(data)
          .then(this.handleResponse)
          .catch(this.handleError);
        
         
      }, 50);
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.reportData = response.data;
      this.totalRecords=response.NumberOfRecords;
      this.pagination.total = response.NumberOfRecords;
    },
  },
};
</script>

<style lang="scss">
.popupRow {
  padding: 5px 0 15px;
}

.pagination {
  margin: 10px 0 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}

.paginationText {
  margin: 0 20px;
}

.paginationButton {
  cursor: pointer;
  width: 26px;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 200ms ease;
}

.paginationButton:hover {
  background: #57a3f3;
  color: #fff;

  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.filter {
  margin: -10px 0 10px;
}

.filterItem {
  display: inline-block;
  margin: 0 15px 15px 0;
}

.filterItemCheckbox {
  flex-basis: auto;
  align-self: flex-start;
  margin-top: 13px;
}
</style>
