
export default [
    // {
    //     type: 'selection',
    //     width: 38,
    //     align: 'center'
    // },

    {
        title: 'Industry Type',
        key: 'cf_853',
        minWidth: 80,
    },
    {
        title: 'Count',
        key: 'count',
        minWidth: 80,
    },
	
]
